<template>
  <main>
    <div class="autocomplete d-flex flex-row">
      <v-combobox
        v-model="selected"
        ref="autocomplete"
        :items="getItems"
        :search-input.sync="search"
        no-filter
        :label="$t('navbar.searchPlaceholder')"
        :placeholder="$t('navbar.searchPlaceholder')"
        background-color="white"
        :flat="!isFocused && !loading"
        item-text="name"
        item-value="name"
        clearable
        solo
        dense
        hide-details
        hide-no-data
        return-object
        type="search"
        :aria-label="$t('navbar.searchPlaceholder')"
        :append-icon="'$search'"
        @click:append="doSearch"
        @focus="onFocus"
        @blur="onBlur"
        @keydown.enter.native.prevent.stop="doSearch"
        :menu-props="{ closeOnContentClick: true }"
      >
        <!-- :menu-props="menuProps" -->
        <!-- :auto-select-first="true" -->
        <!-- cache-items -->
        <!-- :item-text="itemLabel" -->
        <template v-slot:item="{ item }" class="search-item">
          <v-list-item-icon class="search-icon">
            <img v-if="item.type == 'Categorie'" :src="getImage(item)" />
            <v-icon
              v-else-if="item.type == 'Ricerche'"
              @click.prevent.stop="deleteWord(item)"
              small
            >
              mdi-close
            </v-icon>
            <v-icon v-else small>mdi-magnify</v-icon>
          </v-list-item-icon>
          <v-list-item-content @click="doSearch(item)">
            <v-list-item-title>
              <text-highlight :queries="search ? search : '*'">
                {{ item.name }}
              </text-highlight>
            </v-list-item-title>
            <v-list-item-subtitle v-if="item.type == 'Categorie'">
              Cerca <strong>{{ search }}</strong> in {{ item.name }}
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="item.type == 'Marche'">
              Cerca <strong>{{ search }}</strong> con marca {{ item.name }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <div class="badge" v-if="item.count">
              {{ item.count }}
            </div>
            <v-icon
              v-if="item.type == 'Ricerche'"
              @click.prevent.stop="search = item.name"
              small
            >
              mdi-arrow-top-left
            </v-icon>
          </v-list-item-action>
        </template>
      </v-combobox>
      <Barcode v-if="$platform_is_cordova" @input="doSearch" />
    </div>
  </main>
</template>
<style scoped lang="scss">
.search-item {
  min-height: 36px;
}

.search-icon {
  margin-right: 5px !important;
  img {
    width: 30px;
    height: 30px;
    border-radius: 4px;
  }
}
</style>
<script>
import Barcode from "./Barcode";

import ProductService from "~/service/productService";
import AnalyticsService from "~/service/analyticsService";

import debounce from "lodash/debounce";
import forEach from "lodash/forEach";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    Barcode
  },
  data() {
    return {
      loading: false,
      isFocused: null,
      selected: null,
      search: null,
      items: [],
      menuProps: {
        closeOnContentClick: true,
        openOnClick: true
      }
    };
  },
  watch: {
    search(val, oldVal) {
      if (val !== oldVal && val && val.length > 3) {
        this.fetchSuggestion();
      }
    }
  },
  computed: {
    ...mapGetters({
      commonSearch: "wordsSearched/commonSearch"
    }),
    getItems() {
      return this.search ? this.items : this.commonSearch;
    }
  },
  methods: {
    ...mapActions({
      saveWord: "wordsSearched/saveWord",
      deleteWord: "wordsSearched/deleteWord"
    }),
    getImage(item) {
      return "/search-category/" + item.id + ".png";
    },
    onFocus() {
      this.isFocused = true;
    },
    onBlur() {
      this.isFocused = false;
    },
    fetchSuggestion: debounce(async function() {
      let _this = this;
      this.loading = true;
      if (this.search) {
        await ProductService.suggest(this.search.trim()).then(function(
          response
        ) {
          let newItems = [];
          let prevType = "none";

          forEach(response, function(value) {
            if (value.type != prevType) {
              newItems.push({ header: value.type });
              prevType = value.type;
            }
            newItems.push(value);
          });
          _this.items = newItems;
          _this.loading = false;
        });
      }
    }, 200),
    doSearch(item) {
      this.items = [];

      var currentQuery = {};
      if (item.type === "Suggerimenti") {
        //ho selezionato un nome
        currentQuery.q = item.name;
      } else if (item.type === "Categorie") {
        currentQuery.parent_category_id = item.id;
        currentQuery.filter = item.name;
        currentQuery.q = this.search;
      } else if (item.type === "Ricerche") {
        currentQuery.q = item.name;
      } else if (item.type === "Marche") {
        currentQuery.parent_vendor_id = item.id;
        currentQuery.filter = item.name;
        currentQuery.q = this.search;
      } else if (item.type === "Barcode") {
        currentQuery.barcode = item.name;
      } else {
        currentQuery.q = this.search;
        this.saveWord(currentQuery.q);
      }

      if (currentQuery.q || currentQuery.barcode) {
        AnalyticsService.search(this.search);
        //this.$store.dispatch("category/resetFilters");
        global.EventBus.$emit("resetFilters");
        this.$router.push({
          // path: "/search?q=:q&parent_category_id=:parent_category_id",
          path: "/search",
          name: "Search",
          query: currentQuery
        });

        this.$nextTick(() => {
          this.$refs.autocomplete.blur();
          this.search = currentQuery.q;
        });
      }
    }
  },
  mounted() {
    global.EventBus.$on("clearSearch", () => {
      this.search = null;
      this.selected = null;
    });
  },
  beforeDestroy() {
    global.EventBus.$off("clearSearch");
  }
};
</script>
